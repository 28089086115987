import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PersonalBlog from '../containers/home'

const HomePage = (props: any) => {
  const { data } = props;

  return (
    <Layout>
      <SEO
        title="Robert Duraj - daily coding blog"
        description={data.site.siteMetadata.description}
      />
      <PersonalBlog />
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
